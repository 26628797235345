import { FC } from 'react'

export const ProofOfStakeIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3479 18.5772C6.94885 18.5772 2.57141 16.0662 2.57141 12.4668V16.1754C2.57141 19.7748 6.94885 22.2857 12.3479 22.2857C17.747 22.2857 22.1245 19.7748 22.1245 16.1754V12.4677C22.1245 16.0671 17.747 18.5772 12.3479 18.5772Z"
        stroke="#0500E1"
        strokeWidth="1.71429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3659 14.7496C17.7649 14.7496 22.1424 11.8313 22.1424 8.23191C22.1424 4.63252 17.7649 1.71423 12.3659 1.71423C6.96679 1.71423 2.58936 4.63252 2.58936 8.23191C2.58936 11.8313 6.96679 14.7496 12.3659 14.7496Z"
        stroke="#0500E1"
        strokeWidth="1.71429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3659 14.7496C7.24542 14.7496 3.0513 12.1239 2.63091 8.78271H2.58936V12.4669C2.58936 16.0662 6.96679 18.5772 12.3659 18.5772C17.7649 18.5772 22.1424 16.0662 22.1424 12.4669V8.78271H22.1009C21.6804 12.1239 17.4864 14.7496 12.3659 14.7496Z"
        stroke="#0500E1"
        strokeWidth="1.71429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
